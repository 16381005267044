//dependencias
import React, { Component } from 'react';

//componentes
import Cancion from './Cancion'

class Canciones extends Component {

    render(){
        return(
            <div>
                <Cancion />
            </div>
        );
    }

}
export default Canciones;